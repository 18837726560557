import {
  client,
  hostedFields,
  localPayment,
  paypal,
  threeDSecure
} from 'braintree-web';
import {
  setInfo,
  submitValidations,
  clearInfoValidationsAction,
  billingInfoValidations,
  createCustomer,
  updateCustomer,
  receiveCustomer,
  setAddMethod
} from './billing';
import {
  setTransactionNonce,
  cancelProductPayment,
  setPaymentFailed
} from './payments';
import {
  getPaymentInfo,
  getPaymentBillingInfo,
  getPaymentShippingInfo,
  getPremium,
  getTransaction,
  isBillingCardholderValid,
  allFieldsValid,
  getSubscriptionAmount
} from '../selectors/billing';
import {
  getCustomer,
  getCustomerBillingAddress,
  getCustomerShippingAddress
} from '../selectors/customer';
import { setError, deleteErrorsOfBillingForm } from './errors';
import { createErrorObject } from '../utils/dataStore';
import { isEmpty } from '../utils';
import i18n from '../utils/i18n';
import { isUndefined, reject } from 'lodash';
import { URLs } from '../utils/urls';
import { makeAddressObject } from '../utils/user';
import serverRequest from '../utils/Api';
import clientIPAddress from '../utils/ipaddress.js';
import { getCustomerCurrency } from '../selectors/products';
import { getUserBasicInfo, getUserId, getUserToken } from '../selectors/user';
import { localPaymentMethods } from '../utils/constants';

const fields = {
  number: {
    selector: '#card-number',
    placeholder: '•••• •••• •••• 1234'
  },
  cvv: {
    selector: '#cvv',
    placeholder: 'xxx'
  },
  expirationDate: {
    selector: '#expirationDate',
    placeholder: 'mm/yyyy'
  }
};

const styles = {
  input: {
    'font-size': '14px',
    'font-family': 'Lato, sans-serif',
    color: 'rgba(0,0,0,.87)'
  },
  'input::placeholder': {
    'font-size': '14px',
    'font-family': 'Lato, sans-serif',
    color: '#c7c7c7'
  },
  'input.invalid': {
    color: '#9f3a38'
  },
  'input.valid': {
    color: '#24b626'
  }
};

let bindedClickHandler = null;
var threeDSecureInstance = null;
var braintreeClientInstance = null;
var localPaymentInstance = null;

const isTransactionValid = getState => {
  const state = getState();
  const { type } = getPaymentInfo(state); // card || primary || paypal || existing
  const { addMethod } = getPremium(state); // bool

  const cardholderValid = isBillingCardholderValid(state); // bool
  const { valid } = allFieldsValid(getState());

  let isValid = false;
  if (!addMethod) {
    isValid = valid;
  } else {
    isValid = type === 'card' ? cardholderValid : true;
  }

  return isValid;
};

const markInvalidTransaction = (dispatch, validations, tokenizeErr) => {
  dispatch(setInfo({ ready: true, verifying: false, error: true }));
  const validationErrors = Object.assign({}, validations);
  if (tokenizeErr) {
    if (validationErrors.billingInfo && validationErrors.billingInfo.length) {
      validationErrors.billingInfo.push('tokenizeError');
    } else {
      validationErrors.billingInfo = ['tokenizeError'];
    }
    dispatch(submitValidations(tokenizeErr));
  }
  dispatch(
    setError(createErrorObject('', 'form_validations_error', validationErrors))
  );
};

const markThreeDSecureError = (dispatch, validations, threeDSecureError) => {
  const validationErrors = Object.assign({}, validations);
  validationErrors.threeDSecureError = threeDSecureError;
  dispatch(
    setInfo({
      ready: true,
      verifying: false,
      error: validationErrors,
      loading: false
    })
  );
  dispatch(
    setError(createErrorObject('', 'form_validations_error', validationErrors))
  );
};

const markLocalPaymentMethodError = (dispatch, validations, error) => {
  validations.localPaymentError = error;
  dispatch(
    setInfo({
      ready: true,
      verifying: false,
      error: validations,
      loading: false
    })
  );
  dispatch(
    setError(createErrorObject('', 'form_validations_error', validations))
  );
};

const hostedFieldsInstanceTokenizeCallback = (
  element,
  fn,
  dispatch,
  getState,
  context,
  tokenizeErr,
  payload
) => {
  dispatch(deleteErrorsOfBillingForm(context));
  const isValid = isTransactionValid(getState);
  const { validations } = allFieldsValid(getState());

  if (isValid && !tokenizeErr) {
    const { type } = getPaymentInfo(getState()); // card || primary || paypal || existing
    const paymentBillingInfo = getPaymentBillingInfo(getState());
    var address = makeAddressObject(paymentBillingInfo);

    const { billing_address_id } = getCustomer(getState());

    if (billing_address_id === undefined) {
      // customer doesn't exist
      const customer = { billing_address: address };
      if (paymentBillingInfo.taxId) {
        // update taxId in customer --only in subscription flow--
        customer.tax_id = paymentBillingInfo.taxId;
      }
      createCustomer(customer, dispatch, getState()).then(
        _ => {
          if (type === 'card' || type === 'paypal') {
            // new payment method (card or paypal)
            // store the new payment method in the vault.
            // For credit card, this will also verify the card using 3D-Secure after it is stored
            verifyNewPaymentMethod(
              payload.nonce,
              getState,
              fn,
              dispatch,
              context
            );
          } else {
            dispatch(setTransactionNonce(payload.nonce));
            context === 'beacon' ? dispatch(fn('product')) : dispatch(fn());
          }
        },
        err => {
          console.error(
            'hostedFieldsInstanceTokenizeCallback error in createCustomer: ',
            err
          );
        }
      );
    } else {
      // customer exists, update billing address and tax id
      address.id = billing_address_id;
      const customer = {
        billing_address: address,
        tax_id: paymentBillingInfo.taxId
      };
      updateCustomer(customer, dispatch, getState()).then(
        _ => {
          verifyNewPaymentMethod(
            payload.nonce,
            getState,
            fn,
            dispatch,
            context
          );
        },
        err => {
          console.error(
            'hostedFieldsInstanceTokenizeCallback error in updateCustomer: ',
            err
          );
        }
      );
    }
  } else {
    markInvalidTransaction(dispatch, validations, tokenizeErr);
  }
};

const verifyCardWith3DSecure = (
  nonce,
  bin,
  getState,
  ipaddress,
  fn,
  dispatch,
  context
) => {
  /**
   * verify a credit card using 3D-Secure 2
   */
  var bi, si, amount, validations;
  if (context === 'markPaymentMethodAsPrimary') {
    bi = getCustomerBillingAddress(getState());
    si = getCustomerShippingAddress(getState());
    amount = 0.01;
  } else {
    validations = allFieldsValid(getState());
    bi = getPaymentBillingInfo(getState());
    si = getPaymentShippingInfo(getState());
    let transaction = getTransaction(getState());
    amount =
      isUndefined(transaction) || isUndefined(transaction.total_amount)
        ? 0.01
        : transaction.total_amount > 0
        ? transaction.total_amount
        : 0.01;
  }
  if (isEmpty(si)) {
    si = bi;
  }

  return new Promise((resolve, reject) => {
    threeDSecureInstance.verifyCard(
      {
        nonce: nonce,
        bin: bin,
        amount: amount,
        email: getState().user.details.email,
        challengeRequested: true,
        collectDeviceData: true,
        billingAddress: {
          givenName: bi.firstName,
          surname: bi.lastName,
          streetAddress: bi.address,
          locality: bi.locality,
          region: bi.region,
          postalCode: bi.postalCode,
          countryCodeAlpha2: bi.country
        },
        additionalInformation: {
          ipAddress: ipaddress,
          shippingGivenName: si.firstName,
          shippingSurname: si.lastName,
          shippingAddress: {
            streetAddress: si.address,
            locality: si.locality,
            region: si.region,
            postalCode: si.postalCode,
            countryCodeAlpha2: si.country
          }
        },
        onLookupComplete: function(data, next) {
          next();
        }
      },
      function(err, payload) {
        if (err) {
          console.error('Braintree 3DS2 verifyCard error', err);
          if (err.details && err.details.originalError) {
            let errMsg = JSON.stringify(err.details.originalError);
            markThreeDSecureError(dispatch, validations, errMsg);
          } else {
            let errMsg = JSON.stringify(err);
            markInvalidTransaction(dispatch, validations, errMsg);
          }
          reject(err);
        }
        if (payload.liabilityShifted) {
          // Liablity has shifted
          resolve(payload.nonce);
        } else if (payload.liabilityShiftPossible) {
          // Liablity has not shifted but _can_ be shifted (3D-Secure supported)
          // This means that the user failed 3D Secure authentication
          console.error(
            'Braintree 3DS2 verifyCard liabilityShift false liabilityShiftPossible true'
          );
          err =
            payload.threeDSecureInfo && payload.threeDSecureInfo.status
              ? payload.threeDSecureInfo.status
              : i18n.t('billing.formError.unknownValidationError');
          markThreeDSecureError(dispatch, validations, err);
          reject(err);
        } else {
          // Liablity has not shifted and cannot be shifted.
          // The Payment method does not support 3D-secure.
          console.warn(
            'Braintree 3DS2 verifyCard liabilityShift false liabilityShiftPossible false'
          );
          resolve(payload.nonce);
        }
      }
    );
  });
};

const updateCustomerAndCreatePendingOrder = (dispatch, getState, fn) => {
  /**
   * This method creates/updates the customer, and then creates
   * a PENDING order with the local payment `paymentId`.
   * When payment completes asynchronously, Braintree delivers a webhook
   * call to our server with the same `paymentId` and the server can
   * make the sale and mark the order as SUBMITTED.
   */
  const { billing_address_id } = getCustomer(getState());
  const paymentBillingInfo = getPaymentBillingInfo(getState());
  var address = makeAddressObject(paymentBillingInfo);

  if (billing_address_id === undefined) {
    // customer doesn't exist
    const customer = { billing_address: address };
    if (paymentBillingInfo.taxId) {
      // update taxId in customer --only in subscription flow--
      customer.tax_id = paymentBillingInfo.taxId;
    }
    createCustomer(customer, dispatch, getState()).then(
      _ => {
        dispatch(fn('product'));
      },
      err => {
        console.error(
          'updateCustomerAndStartLocalPayment error in createCustomer: ',
          err
        );
        dispatch(setError(createErrorObject(err.message, 'not_allowed_op')));
        dispatch(setPaymentFailed());
      }
    );
  } else {
    // customer exists, update billing address and tax id
    address.id = billing_address_id;
    const customer = {
      billing_address: address,
      tax_id: paymentBillingInfo.taxId
    };
    updateCustomer(customer, dispatch, getState()).then(
      _ => {
        dispatch(fn('product'));
      },
      err => {
        console.error(
          'updateCustomerAndStartLocalPayment error in updateCustomer: ',
          err
        );
        dispatch(setError(createErrorObject(err.message, 'not_allowed_op')));
        dispatch(setPaymentFailed());
      }
    );
  }
};

const startPaymentWithLocalMethod = (type, dispatch, getState, fn) => {
  /**
   * This method is used only for Beacon orders (product payment)
   * Payment with local payment method is not supported for subscriptions / recurring payments.
   */
  if (localPaymentInstance) {
    const { validations } = allFieldsValid(getState());
    let bi = getPaymentBillingInfo(getState());
    let si = getPaymentShippingInfo(getState());
    let transaction = getTransaction(getState());
    let { email } = getUserBasicInfo(getState());
    let amount =
      isUndefined(transaction) || isUndefined(transaction.total_amount)
        ? 0.01
        : transaction.total_amount > 0
        ? transaction.total_amount
        : 0.01;
    if (isEmpty(si)) {
      si = bi;
    }
    var currency = getCustomerCurrency(getState());
    let fallBackUrl =
      process.env.REACT_APP_base_url +
      '/dashboard/beacons?uid=' +
      getUserId(getState()) +
      '&key=' +
      getUserToken(getState());
    let options = {
      paymentType: type,
      paymentTypeCountryCode: bi.country,
      amount: amount,
      fallback: {
        // see Fallback section for details on these params
        url: fallBackUrl,
        buttonText: i18n.t('billing.completePayment')
      },
      currencyCode: currency,
      givenName: bi.firstName,
      surname: bi.lastName,
      email: email,
      shippingAddressRequired: true,
      address: {
        streetAddress: si.address,
        locality: si.locality,
        region: si.region,
        postalCode: si.postalCode,
        countryCode: si.country
      },
      onPaymentStart: function(data, start) {
        const { error: localPaymentError } = getPaymentInfo(getState());
        if (localPaymentError) {
          return;
        }
        dispatch(setInfo({ pid: data.paymentId }));
        dispatch(setTransactionNonce(null));
        // Store the paymentId in a PENDING order on the backend
        updateCustomerAndCreatePendingOrder(dispatch, getState, fn);
        // Call start to initiate the popup
        start();
      }
    };
    localPaymentInstance.startPayment(options, function(
      startPaymentError,
      payload
    ) {
      if (startPaymentError) {
        if (
          ['LOCAL_PAYMENT_WINDOW_CLOSED', 'LOCAL_PAYMENT_CANCELED'].includes(
            startPaymentError.code
          )
        ) {
          console.error('Local Payment canceled.');
        } else {
          console.error('Error!', startPaymentError);
        }
        dispatch(cancelProductPayment());
        markLocalPaymentMethodError(dispatch, validations, startPaymentError);
      } else if (payload) {
        // Send the nonce to the server to create a BT transaction
        dispatch(setTransactionNonce(payload.nonce));
        dispatch(fn('product'));
      }
    });
  }
};

const clearValidationsOnFocus = hostedFieldsInstance => dispatch => {
  hostedFieldsInstance.on('focus', () => {
    dispatch(clearInfoValidationsAction());
  });
};

const clickHandler = (
  dispatch,
  getState,
  hostedFieldsInstance,
  element,
  fn,
  context,
  event
) => {
  const {
    isCard,
    token,
    type,
    ready,
    verifying,
    data: { cardholder = null } = {}
  } = getPaymentInfo(getState());

  const localPaymentMethod = Object.values(localPaymentMethods).find(
    x => x.type === type
  );

  if (!ready || verifying) {
    return;
  }
  dispatch(setInfo({ ready: false, verifying: true, error: null }));
  dispatch(billingInfoValidations());

  dispatch(deleteErrorsOfBillingForm(context));
  switch (type) {
    case 'card':
      hostedFieldsInstance.tokenize(
        { cardholderName: cardholder },
        hostedFieldsInstanceTokenizeCallback.bind(
          this,
          element,
          fn,
          dispatch,
          getState,
          context
        )
      );
      break;
    case 'paypal':
      if (!isTransactionValid(getState)) {
        const { validations } = allFieldsValid(getState());
        markInvalidTransaction(dispatch, validations);
        return;
      }
      hostedFieldsInstance.tokenize(
        { flow: 'vault' },
        hostedFieldsInstanceTokenizeCallback.bind(
          this,
          element,
          fn,
          dispatch,
          getState,
          context
        )
      );
      break;
    default:
      if (localPaymentMethod) {
        dispatch(deleteErrorsOfBillingForm(context));
        if (isTransactionValid(getState)) {
          startPaymentWithLocalMethod(type, dispatch, getState, fn);
        } else {
          const { validations } = allFieldsValid(getState());
          markInvalidTransaction(dispatch, validations);
        }
      } else if (token) {
        if (!isTransactionValid(getState)) {
          const { validations } = allFieldsValid(getState());
          markInvalidTransaction(dispatch, validations);
          break;
        }
        // update customer
        const paymentBillingInfo = getPaymentBillingInfo(getState());
        var address = makeAddressObject(paymentBillingInfo);
        const { billing_address_id } = getCustomer(getState());
        address.id = billing_address_id;
        const customer = {
          billing_address: address,
          tax_id: paymentBillingInfo.taxId
        };
        updateCustomer(customer, dispatch, getState()).then(
          _ => {
            fetchVaultedPaymentMethodNonce(
              token,
              true,
              getState,
              fn,
              dispatch,
              context
            );
          },
          err => {
            console.error('clickHandler error in updateCustomer: ', err);
          }
        );
      }
      break;
  }
};

const addPaymentMethod = (nonce, getState) => {
  /**
   * Add a new credit card to Braintree Vault.
   * This method takes a `nonce` from the hosted fields payload, sends it
   * to the backend and returns the payment method `token`.
   */
  return new Promise((resolve, reject) => {
    const body = { nonce };
    const { url, method } = URLs.customer.addPaymentMethod();
    serverRequest(method, url, getState(), { body }).then(
      json => {
        resolve(json);
      },
      err => {
        reject(err);
      }
    );
  });
};

const verifyNewPaymentMethod = (nonce, getState, fn, dispatch, context) => {
  const { type, isCard } = getPaymentInfo(getState());
  if (type === 'card' || isCard) {
    // store credit card in the vault
    addPaymentMethod(nonce, getState).then(json => {
      dispatch(receiveCustomer(json.customer));
      fetchVaultedPaymentMethodNonce(
        json.token,
        true,
        getState,
        fn,
        dispatch,
        context
      );
    });
  } else {
    // for PayPal, there is no need to verify with 3D-Secure.
    dispatch(setTransactionNonce(nonce));
    context === 'beacon' ? dispatch(fn('product')) : dispatch(fn());
  }
};

export const fetchVaultedPaymentMethodNonce = (
  token,
  verify,
  getState,
  fn,
  dispatch,
  context
) => {
  /**
    Get a fresh nonce for a stored payment method from the backend.
    If `verify` is true, verify the payment method nonce via 3D-Secure. This is done
    during a payment flow, when the user chooses an existing (vaulted) payment method.
  */
  const { method, url } = URLs.customer.getVaultedPaymentMethodNonce(token);
  serverRequest(method, url, getState()).then(
    json => {
      const { nonce, bin } = json;
      const { type, isCard } = getPaymentInfo(getState());
      const doVerify = (
        nonce,
        bin,
        getState,
        ipaddress,
        fn,
        dispatch,
        context
      ) => {
        // verify credit card using 3D-Secure
        verifyCardWith3DSecure(
          nonce,
          bin,
          getState,
          ipaddress,
          fn,
          dispatch,
          context
        ).then(nonce => {
          dispatch(setTransactionNonce(nonce));
          context === 'beacon' ? dispatch(fn('product')) : dispatch(fn());
        });
      };
      if (verify && (type === 'card' || isCard)) {
        // TODO: resolve client IP address for VISA card only
        clientIPAddress().then(
          json => {
            const { ip: ipaddress } = json;
            doVerify(nonce, bin, getState, ipaddress, fn, dispatch, context);
          },
          err => {
            console.error('ERROR cannot resolve client IP address: ' + err);
            doVerify(nonce, bin, getState, null, fn, dispatch, context);
          }
        );
      } else {
        dispatch(setTransactionNonce(nonce));
        context === 'beacon' ? dispatch(fn('product')) : dispatch(fn());
      }
    },
    err => {
      dispatch(setInfo({ loading: false }));
      console.error('getBraintreeNonce: ', err);
    }
  );
};

const typeCreateCallback = (
  element,
  fn,
  dispatch,
  getState,
  context,
  hostedFieldsErr,
  hostedFieldsInstance
) => {
  if (hostedFieldsErr) {
    console.error('hostedFieldsErr', hostedFieldsErr);
    return;
  }
  dispatch(setInfo({ ready: true, loading: false }));
  const { type } = getPaymentInfo(getState());
  type === 'card' && dispatch(clearValidationsOnFocus(hostedFieldsInstance));

  doBindClickHandler(
    element,
    fn,
    dispatch,
    getState,
    context,
    hostedFieldsInstance
  );
};

export const removeClickHandler = () => {
  if (bindedClickHandler) {
    let domElement;
    const addPaymentMethodButton = document.getElementById(
      'addPaymentMethodButton'
    );
    if (addPaymentMethodButton) {
      domElement = addPaymentMethodButton;
    } else {
      domElement = document.getElementById('paymentMethodButton');
    }
    if (domElement) {
      domElement.removeEventListener('click', bindedClickHandler, false);
    }
  }
};

const merchantAccountId = getState => {
  let currency = getCustomerCurrency(getState());
  let merchantAccounts = Object.fromEntries(
    process.env.REACT_APP_bt_merchant_accounts.split(',').map(ac =>
      ac.split(':')
    )
  );
  return merchantAccounts[currency];
};

const doBindClickHandler = (
  element,
  fn,
  dispatch,
  getState,
  context,
  hostedFieldsInstance
) => {
  let domElement;
  const addPaymentMethodButton = document.getElementById(
    'addPaymentMethodButton'
  );
  if (addPaymentMethodButton) {
    domElement = addPaymentMethodButton;
  } else {
    domElement = document.getElementById('paymentMethodButton');
  }
  bindedClickHandler = clickHandler.bind(
    this,
    dispatch,
    getState,
    hostedFieldsInstance,
    element,
    fn,
    context
  );
  domElement && domElement.addEventListener('click', bindedClickHandler, false);
};

const clientCreateCallback = (
  element,
  fn,
  dispatch,
  getState,
  context,
  clientErr,
  clientInstance
) => {
  if (clientErr) {
    console.error('clientErr', clientErr);
    return;
  }
  braintreeClientInstance = clientInstance;
  // Create 3D-Secure component (required for Credit-Card payments)
  threeDSecure.create(
    {
      version: 2, // Will use 3DS 2 whenever possible
      client: clientInstance
    },
    function(threeDSErr, threeDSInstance) {
      if (threeDSErr) {
        // Handle error in 3D Secure component creation
        console.error('threeDSecureErr', threeDSErr);
        return;
      }

      threeDSecureInstance = threeDSInstance;

      if (context === 'validatePaymentMethodForPrimary') {
        dispatch(fn());
        return;
      }

      const options = {
        client: clientInstance,
        fields,
        styles
      };

      const { type, isCard } = getPaymentInfo(getState());
      switch (type) {
        case 'card':
          hostedFields.create(
            options,
            typeCreateCallback.bind(
              this,
              element,
              fn,
              dispatch,
              getState,
              context
            )
          );
          break;
        case 'paypal':
          paypal.create(
            options,
            typeCreateCallback.bind(
              this,
              element,
              fn,
              dispatch,
              getState,
              context
            )
          );
          break;
        default:
          // if (isCard) {
          doBindClickHandler(element, fn, dispatch, getState, context, null);
          // }
          break;
      }
    }
  );
  // Create a local payment component (required for local payment methods, e.g. iDeal).
  // https://developer.paypal.com/braintree/docs/guides/local-payment-methods/client-side-custom/javascript/v3
  localPayment.create(
    {
      client: clientInstance,
      merchantAccountId: merchantAccountId(getState)
    },
    function(localPaymentErr, paymentInstance) {
      // Stop if there was a problem creating local payment component.
      // This could happen if there was a network error or if it's incorrectly
      // configured.
      if (localPaymentErr) {
        console.error('Error creating local payment:', localPaymentErr);
        return;
      }

      localPaymentInstance = paymentInstance;
    }
  );
};

export const clientCreate = (
  token,
  element,
  fn,
  dispatch,
  getState,
  context
) => {
  client.create(
    { authorization: token },
    clientCreateCallback.bind(this, element, fn, dispatch, getState, context)
  );
};

export const clientClose = () => {
  if (threeDSecureInstance) {
    threeDSecureInstance.teardown();
    threeDSecureInstance = null;
    removeClickHandler();
  }
  if (braintreeClientInstance) {
    braintreeClientInstance.teardown();
    braintreeClientInstance = null;
  }
};
